<template>
  <div style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
      <div class="live-top">
        <div class="live-top-input">
          <div>年份:</div>
          <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
        </div>
      </div>
      <div style="width: 98%;margin: 20px auto;font-weight: bold">7月份总得分值：150.2，2023年总得分值：320.8</div>
      <a-table
          style="width: 98%;margin: auto"
          :rowKey='record=>record.id'
          :pagination="false"
          :columns="columns"
          :data-source="dataList">
         <span slot="system" slot-scope="text, record">
           <a @click="openModel">查看评分详情</a>
         </span>
      </a-table>
      <div style="width: 100%">
        <div style="float: right;margin-right: 20px;margin-top: 15px;margin-bottom: 15px">
          <a-pagination
              show-quick-jumper
              :current="page_no"
              :total="total"
              @change="paginationChange" />
        </div>
      </div>

      <!--------------------------------------对话框------------------------>
      <a-drawer
          :get-container="false"
          @close="add_cancel"
          :width="1300"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visible"
          title="直播评分">
        <div style="width: 100%;margin: auto;display: flex;justify-content: space-between;margin-bottom: 10px">
          <div><span style="color: red">*</span> 直播评分</div>
        </div>
        <a-table
            bordered
            style="width: 100%;margin: auto"
            :rowKey='record=>record.id'
            :pagination="false"
            :columns="columnsModel"
            :data-source="dataModel">
          <template slot="TotalScore" slot-scope="text, record">
           {{text}}
          </template>
          <template slot="TotalDemerit" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="DeductionDemerit" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="SalesDemerit" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="ReasonSupervisorPoints" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
          </template>
          <template slot="SalesDeductionReasons" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
          </template>
         <span slot="system" slot-scope="text, record">
           <a>评分</a>
         </span>
          <template slot="footer" slot-scope="currentPageData">
            最终总得分：120 ， 被扣除分数：20
          </template>
        </a-table>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="add_cancel">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
            确认
          </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>
</template>

<script>

import moment from "moment";
import {current_date} from '@/utils/currentDate'
const columns = [
  {
    title: '会议标题',
    dataIndex: 'title',
    key: 'title',
    width:'35%',
    ellipsis: true,
  },
  {
    title: '会议时间',
    dataIndex: 'time',
    key: 'time',
    width:'20%',
  },
  {
    title: '销售负责人',
    dataIndex: 'businessManager',
    key: 'businessManager',
    scopedSlots: { customRender: 'businessManager' },
  },{
    title: '得分数',
    dataIndex: 'goalsFor',
    key: 'goalsFor',
    width:'10%',
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    width:'15%',
    scopedSlots: { customRender: 'system' },
  },
];
const dataModel = [
  {
    id:1,
    type:'会议直播',
    content:'线上直播',
    TotalScore:11,
    TotalDemerit:null,
    DeductionDemerit:null,
    ReasonSupervisorPoints:null,
    SalesDemerit:null,
    SalesDeductionReasons:null,
  },
  {
    id:4,
    type:'设计',
    content:'姓名条',
    TotalScore:11,
    TotalDemerit:2,
    DeductionDemerit:1,
    ReasonSupervisorPoints:'不太好',
    SalesDemerit:1,
    SalesDeductionReasons:'不太好',
  },{
    id:5,
    type:'设计',
    content:'串场',
    TotalScore:11,
    TotalDemerit:2,
    DeductionDemerit:1,
    ReasonSupervisorPoints:'不太好',
    SalesDemerit:1,
    SalesDeductionReasons:'不太好',
  },
];
const columnsModel = [
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    customRender (_, row) {
      return {
        children: row.type,
        attrs: {
          rowSpan: row.typeRowSpan
        }
      }
    }
  },
  {
    title: '内容',
    dataIndex: 'content',
    key: 'content',
    customRender (_, row) {
      return {
        children: row.content,
        attrs: {
          rowSpan: row.contentRowSpan
        }
      }
    }
  },
  {
    title: '得分值',
    dataIndex: 'TotalScore',
    key: 'TotalScore',
    scopedSlots: { customRender: 'TotalScore' },
  },
  {
    title: '总扣分',
    dataIndex: 'TotalDemerit',
    key: 'TotalDemerit',
    scopedSlots: { customRender: 'TotalDemerit' },
  },{
    title: '主管扣分',
    dataIndex: 'DeductionDemerit',
    key: 'DeductionDemerit',
    scopedSlots: { customRender: 'DeductionDemerit' },
  },{
    title: '主管扣分理由',
    dataIndex: 'ReasonSupervisorPoints',
    key: 'ReasonSupervisorPoints',
    scopedSlots: { customRender: 'ReasonSupervisorPoints' },
  },{
    title: '销售扣分',
    dataIndex: 'SalesDemerit',
    key: 'SalesDemerit',
    scopedSlots: { customRender: 'SalesDemerit' },
  },{
    title: '销售扣分理由',
    dataIndex: 'SalesDeductionReasons',
    key: 'SalesDeductionReasons',
    scopedSlots: { customRender: 'SalesDeductionReasons' },
  },
];
export default {
  data(){
    return{
      year:current_date(),
      visible:false,
      visibleModal:false,
      yearShowOne:false,
      columnsModel,
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      dataList:[
        {
          id:1,
          title:'11111',
          time:'11111',
          businessManager:'11111',
          goalsFor:'11111',
        }
      ],
      dataModel,
      columns,
      page_no:1,
      page_size:10,
      total:null,
      spinning:false,
    }
  },
  created() {

  },
  mounted() {
    this.mergeRowCell(this.dataModel)
  },
  methods:{
    // 合并单元格
    rowSpan (key, data) {
      const arr = data
          .reduce((result, item) => {
            if (result.indexOf(item[key]) < 0) {
              result.push(item[key])
            }
            return result
          }, [])
          .reduce((result, keys) => {
            const children = data.filter(item => item[key] === keys)
            result = result.concat(
                children.map((item, index) => ({
                  ...item,
                  [`${key}RowSpan`]: index === 0 ? children.length : 0
                }))
            )
            return result
          }, [])
      return arr
    },
    // 表格合并
    mergeRowCell (data) {
      let tableData = this.rowSpan('type', data)
      tableData = this.rowSpan('content', tableData)
      this.dataModel = tableData
    },
    paginationChange(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      this.page_no = 1
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList()
    },
    add_cancel(){
      //  关闭对话框
      this.visible = false;
    },
    async add_content_list_Ok() {
      this.$refs.content_list_Form_add.validate(async valid => {
        if (valid) {

        } else {
          return false;
        }
      });
    },
    subtractBtn(){

    },
    addBtn(){

    },
    openModel(){
      this.visible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.live-top{
  height: 80px;
  width: 100%;
  display: flex;
  border-bottom: 10px solid #f5f5f5;
  padding-top: 15px;
  .live-top-input{
    width: 25%;
    display: flex;
    height: 35px;
    line-height: 35px;
    padding-left: 30px;
    .input{
      width: 65%;
      margin-left: 5px;
    }
  }

}
</style>
